.translation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
    color: var(--tearsgreen-rgb);
    padding-right: 1vw;
    padding-top: 1vw;
}

.translation a {
    color: var(--tearsgreen-rgb);
    text-shadow: 0 0 4px var(--tearsgreen-rgb);
    text-decoration: none;
    border-bottom: 1px solid var(--tearsgreen-rgb);
}

.translation a > svg {
    width: 0.9rem;
    height: 0.9rem;
    color: var(--tearsgreen-rgb);
    margin-right: 5px;
}

.translation a > span {
    font-size: 0.8rem;
    vertical-align: text-top;
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.links a {
    display: flex;
    text-decoration: none;
    padding: 2px;
}

.links a span:first-child {
    text-shadow: 0 0 4px var(--tearsgreen-rgb);
    text-decoration: none;
    font-size: 0.8rem;
}

.links a span:nth-child(2) {
    color: var(--tearsgreen-rgb);
    font-size: 0.85rem;
    letter-spacing: 1.2px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
    text-shadow: 0 0 1px var(--tearsgreen-rgb);
}

.links a span:nth-child(2):hover {
    text-decoration: underline;
}