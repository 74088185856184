#childrenContainer.browser {
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: auto;
}

.grid-container {
    font-family: anon;
}

/* ### PRINCIPAL-GRID */

.principal-grid {
    height: 95vh;
}

.principal-grid .mossgreen-window {
    width: 28vw;
}

.principal-grid .mossgreen-window h3 {
    padding: 10px;
}

.principal-grid .menu {
    position: relative;
    top: -17%;
    left: -20px;
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: transparent;
}

.principal-grid .menu button {
    margin-bottom: 5px;
}

.principal-grid .personal-data .title {
    font-size: 0.9rem;
    color: var(--matrix-rgba-50);
}

.principal-grid .personal-data > span {
    margin-bottom: 4px;
    margin-left: 15px;
    text-shadow: 0px 1px 1px var(--tearsgreen-rgb);
}

.principal-grid .personal-data span a {
    color: var(--lightblue-rgb);
}

.principal-grid .personal-data > span::before {
    content: '> ';
}

.principal-grid .personal-data .links a {
    padding-top: 5px;
}

/* ### SECTION-GRID */

.grid-container .sections-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-shadow: 0px 1px 1px var(--tearsgreen-rgb);
}

.grid-container .sections-grid > div {
    flex: 1;
    margin-right: 5px;
    font-size: .9rem;
    border: 2.5px solid transparent;
    height: 95vh;
    overflow-y: auto;
}

.grid-container .sections-grid .description {
    margin-bottom: 15px;    
}

.grid-container .sections-grid .description > span:first-child::before {
    content: '> ';
}

.grid-container .sections-grid .questionary .questions {
    padding-left: 10px;
    padding-right: 10px;
}